import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"


import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import WorkTitle from '../../components/WorkTitle'
import WorkTitleBlock from '../../components/WorkTitleBlock';


export const frontmatter = {
  title: 'Sheffield Refractories Advertisement',
  type: 'Print',
  client: 'Sheffield Refractories',
  path: '/works/print/shtr-ad/',
  date: '2012-08-30',
  image: 'shtr-ad-demo',
  related: '/works/web/shtr/',
}

const related = [
  {
    title: 'Site',
    href: frontmatter.related,
  },
  {
    title: frontmatter.type,
    href: frontmatter.path,
  },
]

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/shtr/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
  <Layout location={location}>
    <Helmet title={frontmatter.title} />

    <WorkTitle title={frontmatter.title} related={related} />
    
    <figure className="text-center" style={{ maxWidth: 900, margin: '0 auto 50px auto'}}>
      <Img
        fluid={findImage(images, 'shtr-ad')}
        alt="Sheffield Refractories magazine advertisement print"
      />
    </figure>

    <WorkTitleBlock {...frontmatter} />

  </Layout>
  )
}
