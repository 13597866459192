import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../Menu'

import styles from './related.module.scss'

const WorksRelated = ({ items }) => {
  return <Menu className={styles.menu} items={items} />
}

WorksRelated.propTypes = {
  items: PropTypes.array.isRequired,
}

export default WorksRelated
