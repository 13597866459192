import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-flexbox-grid'

import WorksRelated from '../../components/WorksRelated'

const WorkTitle = ({ title, related }) => {
  if (related) {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={6} smOffset={3}>
            <h1 className="text-center">{title}</h1>
          </Col>
          <Col xs={12} sm={3}>
            <WorksRelated items={related} />
          </Col>
        </Row>
      </Grid>
    )
  } else {
    return <h1 className="text-center">{title}</h1>
  }
}

WorkTitle.propTypes = {
  title: PropTypes.string.isRequired,
  related: PropTypes.array,
}

export default WorkTitle
